<template>
  <div class="main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="col-12">
        <router-link :to="'/programme'">
          Programme>
        </router-link>
        <router-link :to="`/programme/detailProgramme/${this.$route.params.id}`">
          Détails du programme>
        </router-link>
        <span>Modification de programme</span>
      </div>
    </div>

    <div class="row justify-content-center mb-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Modification de programme
          </div>
        </div>
      </div>
    </div>

    <div v-if="this.$route.params.id">
      <div class="inner_container">
        <div class="row">
          <Notif :notif="notif" />
        </div>

        <div class="row">
          <div class="col-md-3">
            <label>Catégorie du programme</label>
            <select class="form-control" 
                    name="" 
                    id=""
                    v-model="form.categorie_programme">
              <option value="programme_investissement">Programme d'investissement</option>
              <option value="programme_entretien_routier">programme d'entretien routier</option>
            </select>  
          </div>
          <div class="col-md-3  ">
            <label class="req">Nom du programme</label>
            <input type="Text"
                   id=""
                   class="form-control"
                   v-model="form.nom_programme" />
          </div>
          <!-- <div class="col-md-3">
            <label>Année d'exercice</label>
            <input
              type="number"
              name="annee_exercice"
              v-model="form.annee_exercice"
              :ref="'annee_exercice'"
              class="form-control form-control-lg"
              v-on:click="removeSPan('annee_exercice')"
              v-on:change="getDate()"
              aria-label=".form-control-lg example"
            />
            <span v-if="form.errors().has('categorie_programme') && affiche.categorie_programme !== 'categorie_cloture_programme'"
                  v-text="form.errors().get('categorie_cloture_programme')"
                  class="errorMsg"> </span>
          </div> -->
          <div class="col-md-3">
            <label>Date de lancement</label>
            <input
              type="date"
              name="dateLancement"
              min="0"
              max="999999999999"
              v-model="form.date_lancement_programme"
              class="form-control"
              @change="checkbudget(form.date_lancement_programme)"
              v-on:click="removeSPan('date_lancement_programme')"
              aria-label=" example"
            />  
          </div>
        </div>
        <div class="row">
          <div class="col-md-3  ">
            <label class="req">Date début</label>
            <input type="date"
                   class="form-control"
                   v-model="form.date_debut" />
          </div>
          <div class="col-md-3">
            <label class="req">Date fin</label>
            <input
              type="date"
              name="date_fin"
              v-model="form.date_fin"
              :ref="'date_fin'"
              class="form-control"
              v-on:click="removeSPan('date_fin')"
              @blur="getDate()"
              aria-label=" example"
            />
           
          </div>
          <div class="col-md-3">
            <label>Date de validation</label>
            <input type="date"
                   name="libelle"
                   v-model="form.date_validation_programme"
                   class="form-control"
                   v-on:click="removeSPan('date_validation_programme')"
                   aria-label=" example" />
            
          </div>
          <div class="col-md-3">
            <label>Date de clôture</label>
            <input
              type="date"
              name="date_cloture"
              v-model="form.date_cloture_programme"
              :ref="'date_cloture_programme'"
              class="form-control"
              v-on:click="removeSPan('date_cloture_programme')"
              v-on:change="getDate()"
              aria-label=" example"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <label>Date de rapport de clôture</label>
          <input
            type="date"
            name="date_transmission_rapport_cloture_programme"
            v-model="form.date_transmission_rapport_cloture_programme"
            :ref="'date_transmission_rapport_cloture_programme'"
            class="form-control"
            v-on:click="removeSPan('date_transmission_rapport_cloture_programme')"
            v-on:change="getDate()"
            aria-label=".form-control example"
          />
            
        </div>
        <div class="col-md-3">
          <label class="req">Montant initial</label>
          <input type="text"
                 @change="checkbudget(montant_initial_programme)"
                 @input="addSpace('montant_initial_programme')"
                 min="0"
                 class="form-control"
                 disabled
                 v-model="montant_initial_programme" />
        </div>
        <div class="col-md-3">
          <label>Montant révisé</label>
          <input
            type="text"
            name="budget"
            min="0"
            max="999999999999"
            v-model="montant_revise"
            class="form-control form-control-lg"
            @change="checkbudget(montant_revise)"
            @input="addSpace('montant_revise')"
            aria-label=".form-control-lg example"
          />
          <span v-if="form.errors().has('montant_revise') && affiche.montant_revise !== 'montant_revise'"
                v-text="form.errors().get('montant_revise')"
                class="errorMsg"> </span>
        </div>
        <div class="col-md-3">
          <label>Montant définitif</label>
          <input
            type="text"
            name="budget"
            min="0"
            max="999999999999"
            v-model="montant_definitif"
            disabled
            class="form-control form-control-lg"
            @change="checkbudget(montant_definitif)"
            @input="addSpace('montant_definitif')"
            aria-label=".form-control-lg example"
          />
          <span v-if="form.errors().has('montant_definitif') && affiche.montant_definitif !== 'montant_definitif'"
                v-text="form.errors().get('montant_definitif')"
                class="errorMsg"> </span>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-md-12">
          <label>Description</label>
          <textarea class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    v-model="form.description"> </textarea>
        </div>
      </div>

      <div class="table_holder">
        <div class="tab_holder">
          <div class="col_table">
            <div class="col_header">
              <div class="row">
                <div class="col-5">DESIGNATION</div>
                <div class="col-3 text-center">OBSERVATION</div>
                <div class="col-2 text-center">MONTANT INITIAL</div>
                <div class="col-2 text-center">MONTANT REVISE</div>
              </div>
            </div>
            <div class="row no-mr sub-header"
                 v-for="(rubrique, rubkey) in progrubriques.donnees"
                 :key="rubkey">
              <div class="col-md-12">
                <div class="row light_row">
                  <div class="col-md-12">
                    <label>{{ rubrique.libelle }}</label>
                  </div>
                </div>
                <div class="row alter tableview"
                     v-for="(activite, actkey) in rubrique.activites"
                     :key="actkey">
                  <div class="col-md-5">
                    <input
                      v-if="activites[activite.counter] !== undefined && activites[activite.counter].montant != 0"
                      type="checkbox"
                      :ref="'check' + activite.id"
                      @click="enabled(activite.id)"
                      checked
                    />
                    <input v-else
                           type="checkbox"
                           :ref="'check' + activite.id"
                           @click="enabled(activite.id)" />
                    <span class="ml-4">{{ activite.libelle }}</span>
                  </div>
                  <div class="col-md-3 tb_row"
                       v-if="activites[activite.counter] !== undefined">
                    <textarea type="text"
                              style="font-size: 1em;"
                              :ref="'obs' + activite.id"
                              class="form-control observation"
                              required
                              disabled
                              v-model="activites[activite.counter].observation"> </textarea>
                  </div>
                  <div class="col-md-2 tb_row"
                       v-if="activites[activite.counter] !== undefined">
                    <input
                      @change="checker(activite.id)"
                      style="font-size: 1em;"
                      type="text"
                      min="0"
                      max="999999999"
                      class="form-control montant"
                      disabled
                      v-model="activites[activite.counter].montant"
                    />
                  </div>
                  <div class="col-md-2 tb_row"
                       v-if="activites[activite.counter] !== undefined">
                    <input
                      @change="checker(activite.id)"
                      @input="addSpaceActivity(activite.counter)"
                      style="font-size: 1em;"
                      type="text"
                      min="0"
                      max="999999999"
                      :ref="'mont' + activite.id"
                      class="form-control montant"
                      disabled
                      v-model="activites[activite.counter].montant_revise"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else
         class="row  mb-4">
      {{ noData }}
    </div>

    <div class="row action_bar mt-3">
      <div class="col-auto">
        <button type="button"
                class="btn btn-f-blue ml-2"
                data-toggle="modal"
                data-target="#exampleModal"><i class="flaticon flaticon-cancel"></i> &nbsp; &nbsp;Annuler</button>
      </div>
      <div class="col-auto">
        <button type="button"
                class="btn btn-f-blue ml-1"
                @click="onSubmit"><i class="flaticon flaticon-folder"></i>&nbsp; &nbsp;Enregistrer</button>
      </div>
    </div>
    <!-- modal box -->
    <div class="modal fade"
         id="exampleModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog"
           role="document">
        <div class="modal-content">
          <div class="modal-header ">
            <h5 class="modal-title text-uppercase"
                id="exampleModalLabel">Annulation</h5>
            <button type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Cette action est irréversible, êtes-vous sure de vouloir annuler ?</p>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal">Non</button>
            <button type="button"
                    @click="goBack()"
                    data-dismiss="modal"
                    class="btn btn-primary">Oui</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
@import "./programme.css";
.col_table .col_header,
.col_table .sub-header label,
.col_table .sub-header .tableview
{
  font-size: 0.48em;
}
div.col-md-3 select.form-control{
  font-size: 0.6em;
}
div.col-md-3 select.form-control option{
  font-size: 0.9em;
}
.observation,.montant
{
  font-size: 0.98em;
}
</style>
<script>
import form from "vuejs-form"
import Notif from "@/components/shared/Toast"
import utils from "@/assets/js/utils"

import { mapActions, mapGetters, mapMutations } from "vuex"
export default {
  name: "ModifyProgramme",
  components: {
    Notif
  },
  data: () => ({
    noData: "",
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    montant_initial_programme:"",
    montant_revise:"",
    montant_definitif:"",
    activites: [],
    form: form({
      nom_programme: "",
      montant_initial_programme: "",
      montant_revise: "",
      montant_definitif: "",
      date_debut: "",
      date_fin: "",
      categorie_programme:"",
      date_transmission_rapport_cloture_programme: "",
      date_validation_programme: "",
      date_lancement_programme: "",
      date_cloture_programme: "",
      annee_exercice: "",
      description: "",
      activites: []
    }),
    formData: {
      nom_programme: "",
      montant_initial_programme: "",
      montant_revise: 0,
      montant_definitf: 0,
      date_debut: "",
      date_fin: "",
      categorie_programme:"",
      date_transmission_rapport_cloture_programme: "",
      date_validation_programme: "",
      date_lancement_programme: "",
      date_cloture_programme: "",
      annee_exercice: "",
      description: "",
      activites: []
    },
    send: false,
    activitesList: [],
    gestion_date_debut: "date_debut",
    gestion_date_fin: "date_fin"
  }),
  watch: {
    ["form.data"]: {
      deep: true,
      immediate: false,
      handler: "onFormChange"
    },

    progrubriques() {
      const rubriqueArr = this.progrubriques.donnees
      var counter = 0
      var listActivities = []
      rubriqueArr.filter(function(actArr, rubindex) {
        actArr.activites.filter(function(activity, actindex) {
          const insertActivity = { id: "", observation: "", montant: "", montant_revise: "" }
          insertActivity.id = activity.id
          insertActivity.observation = activity.observation
          insertActivity.montant = activity.montant_budgete
          insertActivity.montant_revise = isNaN(Math.round(Number(activity.montant_revise)))?0:Math.round(Number(activity.montant_revise))
          listActivities.push(insertActivity)
          rubriqueArr[rubindex].activites[actindex]["counter"] = counter
          counter += 1
        })
      })
      this.progrubriques.donnees = rubriqueArr
      this.form.activites = listActivities
      this.activites = listActivities
      // this.activitesList = listActivities
      this.getProgramme(this.$route.params.id)
    },
    updateProgrammemsg() {
      if (this.updateProgrammemsg) {
        this.notif.message = this.updateProgrammemsg
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.updateProgramme("")
            // location.reload()
            this.$router.push({ name: "Programme" })
          }.bind(this),
          3000
        )
      }
    },
    progerrors(){
      if (this.progerrors) {
        if (this.progerrors.length > 1) {
          this.progerrors.forEach(erreur => {
            this.notif.message += erreur + " "
          })
        }else{
          this.notif.message = this.progerrors
        }
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setErors("")
          }.bind(this),
          3000
        )
      }
    },
    assignProgramme() {
      // var counter = 0
      // var listActivities = []
      if (this.assignProgramme.programme) {
        this.form.nom_programme = this.assignProgramme.programme.nom_programme
        this.form.montant_initial_programme = Math.round(Number(this.assignProgramme.programme.montant_initial_programme))
        this.montant_initial_programme = Math.round(Number(this.assignProgramme.programme.montant_initial_programme)).toLocaleString()
        this.form.montant_revise = Math.round(Number(this.assignProgramme.programme.montant_revise))
        this.montant_revise = Math.round(Number(this.assignProgramme.programme.montant_revise))
        this.form.montant_definitif = Math.round(Number(this.assignProgramme.programme.montant_definitif))
        this.montant_definitif = Math.round(Number(this.assignProgramme.programme.montant_definitif)).toLocaleString()
        this.form.annee_exercice = this.assignProgramme.programme.annee_exercice
        this.form.date_debut = this.assignProgramme.programme.date_debut
        this.form.date_fin = this.assignProgramme.programme.date_fin
        if (this.assignProgramme.programme.date_validation_programme !="0000-00-00") {
          this.form.date_validation_programme = this.assignProgramme.programme.date_validation_programme
        }
        if (this.assignProgramme.programme.date_transmission_rapport_cloture_programme !="0000-00-00") {
          this.form.date_transmission_rapport_cloture_programme = this.assignProgramme.programme.date_transmission_rapport_cloture_programme
        }
        if (this.assignProgramme.programme.date_lancement_programme !="0000-00-00") {
          this.form.date_lancement_programme = this.assignProgramme.programme.date_lancement_programme
        }
        if (this.assignProgramme.programme.date_cloture_programme !="0000-00-00") {
          this.form.date_cloture_programme = this.assignProgramme.programme.date_cloture_programme
        }
        this.form.categorie_programme = this.assignProgramme.programme.categorie_programme
        this.form.description = this.assignProgramme.programme.description
        const assRub = this.assignProgramme.programme.rubriques
        this.form.activites.filter(function(factArr) {
          assRub.filter(function(rubArr) {
            rubArr.activites.filter(function(actArr) {
              if (factArr.id == actArr.id) {
                factArr.observation = actArr.observation
                factArr.montant = Math.round(Number(actArr.montant_budgete))
                factArr.montant_revise = Math.round(Number(actArr.montant_revise)).toLocaleString()
              }
            })
          })
        })
        
        this.assignProgramme.programme.rubriques.forEach(rubrique => {
          rubrique.activites.forEach(activite => {
            this.enabled(activite.id)
            this.$refs["check" + activite.id][0].checked = true

          })
        })
        this.activites.forEach(activite =>{
          activite.montant= Math.round(Number(activite.montant)).toLocaleString()
        })
      }
    }
  },
  created() {
    this.getRubriques()
  },
  computed: {
    ...mapGetters(["assignProgramme", "updateProgrammemsg", "progerrors", "progrubriques"])
  },
  methods: {
    ...mapActions(["getProgramme", "updateProgrammes", "getRubriques"]),
    ...mapMutations(["updateProgramme", "setErors"]),
    onFormChange() {
      this.form.validate()
    },
    checker(id) {
      if (this.$refs["mont" + id][0].value == 0) {
        this.enabled(id)
        this.$refs["check" + id][0].checked = false
      }
      if (this.$refs["mont" + id][0].value > 999999999999) {
        var message = "le montant ne peut pas être supérieur à 999.999.999.999"
        this.notif.message = message
        this.notif.type = "toast-warning"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          3000
        )
      }
    },
    checkbudget(budget) {
      if (budget > 999999999999) {
        var message = "Veuillez renseigner un montant inférieur à 999.999.999.999"
        this.notif.message = message
        this.notif.type = "toast-warning"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          3000
        )
      }else{
        this.form.montant_definitif= this.form.montant_initial_programme+this.form.montant_revise
        this.montant_definitif = this.form.montant_definitif.toString()
        this.addSpace("montant_definitif")
      }
    },
    enabled(id) {
      if (this.$refs["obs" + id][0].disabled == true) {
        this.$refs["obs" + id][0].disabled = false
        this.$refs["mont" + id][0].disabled = false
      } else {
        this.$refs["obs" + id][0].disabled = true
        this.$refs["obs" + id][0].value = ""
        this.$refs["mont" + id][0].disabled = true
        this.$refs["mont" + id][0].value = 0
        this.form.activites.forEach(activite => {
          if (activite.id == id) {
            activite.montant_revise = 0
            // activite.observation = ""
          }
        })
      }
    },
    addSpace(key) {
      var chaine = ""
      var splinter = this[key].split("")
      var res =""
      this.form[key]=""
      if (/[0-9]/.test(this[key]) || !/[A-Za-zéèàôêâîûöïüëä$&+,:;=?@#|"~{}'<>.^*()%!]/.test(this[key])) {
        for (let index = 0; index < splinter.length; index++) {
          if (index==0) {
            if (!/[0-9]/.test(splinter[index]) && !splinter[index] == " " && splinter[index]!='-') {
              splinter[index] = ""
            }
          }else{
            if (!/[0-9]/.test(splinter[index]) && !splinter[index] == " ") {
              splinter[index] = ""
            }
          }
          chaine += splinter[index]
        }
        if (this[key].length >= 4) {
          // check if there's a space
          if (this[key].includes(" ")) {
            var trimer = chaine.split(" ")
            chaine = ""
            for (let index = 0; index < trimer.length; index++) {
              chaine += trimer[index]
            }
            chaine = chaine.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          } else {
            chaine = ""
            chaine = this[key].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          }
        }
      }
      this[key] = chaine
      res = this[key].split(" ")
      for (let index = 0; index < res.length; index++) {
        this.form[key] += res[index]
      }
      this.form[key]=Number(this.form[key])
    },
    addSpaceActivity(key) {
      var chaine = ""
      var splinter = ""
      splinter = this.activites[key].montant_revise.split("")
      // var res =""
      if (/[0-9]/.test(this.activites[key].montant_revise) || !/[A-Za-zéèàôêâîûöïüëä$&+,:;=?@#|"~{}'<>.^*()%!]/.test(this.activites[key].montant)) {
        for (let index = 0; index < splinter.length; index++) {
          if (index==0) {
            if (!/[0-9]/.test(splinter[index]) && !splinter[index] == " " && splinter[index]!='-') {
              splinter[index] = ""
            }
          }else{
            if (!/[0-9]/.test(splinter[index]) && !splinter[index] == " ") {
              splinter[index] = ""
            }
          }
          chaine += splinter[index]

        }
        if (splinter.length >= 4) {
          // check if there's a space
          if (splinter.includes(" ")) {
            var trimer = chaine.split(" ")
            chaine = ""
            for (let index = 0; index < trimer.length; index++) {
              chaine += trimer[index]
            }
            chaine = chaine.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          } else {
            trimer = chaine
            chaine = ""
            chaine = trimer.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          }
        }
      }
      
      this.activites[key].montant_revise = chaine
    },
    onSubmit() {
      this.formData.nom_programme = this.form.data.nom_programme
      this.formData.montant_initial_programme = this.form.data.montant_initial_programme
      this.formData.montant_revise = this.form.data.montant_revise
      this.formData.date_debut = this.form.data.date_debut
      this.formData.date_fin = this.form.data.date_fin
      this.formData.date_validation_programme = this.form.data.date_validation_programme
      this.formData.date_lancement_programme = this.form.data.date_lancement_programme
      this.formData.date_transmission_rapport_cloture_programme = this.form.data.date_transmission_rapport_cloture_programme
      this.formData.date_cloture_programme = this.form.data.date_cloture_programme
      this.formData.categorie_programme = this.form.data.categorie_programme
      this.formData.description = this.form.data.description
      this.formData.annee_exercice = this.form.data.annee_exercice
      this.activitesList = []
      // Vérifier si  montant_revisé_activite + montant_initial_marche <= montant_definitif_programme
      var montant_total_activite=0
      this.activites.forEach(activite => {
        if (activite.montant_revise.length > 3) {
          if (activite.montant_revise.includes(" ")) {
            var montant= activite.montant_revise.split(" ")
            var result=""
            for (let index = 0; index < montant.length; index++) {
              result+=montant[index]
            }
            montant_total_activite+=Number(result)
            // console.log(Number(result))

          }
        }else{
          montant_total_activite+=Number(activite.montant_revise)
          // console.log(Number(activite.montant_revise))
        }
        if (activite.montant.length > 3) {
          if (activite.montant.includes(" ")) {
            montant= activite.montant.split(" ")
            result=""
            for (let index = 0; index < montant.length; index++) {
              result+=montant[index]
            }
            montant_total_activite+=Number(result)
          }
        }else{
          montant_total_activite+=Number(activite.montant)
        }
      })
      // console.log("montant_total_activite")
      // console.log(montant_total_activite)
      // console.log("montant_definitif_programme")
      // console.log(this.form.montant_definitif)
      if (montant_total_activite <= this.form.montant_definitif) {
        if (
          !this.form
            .validate()
            .errors()
            .any()
        ) {
          console.log('====================================')
          console.log(this.activites)
          console.log('====================================')
          this.activites.forEach(activite => {
            if (((activite.observation != "" && activite.montant_revise != 0) ||(activite.montant != 0) ) || (activite.observation == "Données Historiques RAS" )) {
              if (activite.montant.includes(" ")) {
                var montant= activite.montant_revise.split(" ")
                var res=""
                for (let index = 0; index < montant.length; index++) {
                  res+=montant[index]
                }
                activite.montant_revise=Number(res)
              }else{
                if (activite.montant_revise != 0) {
                  montant = activite.montant_revise.split("")
                }else{
                  montant = activite.montant_revise
                }
                res=""
                for (let index = 0; index < montant.length; index++) {
                  if (index==0) {
                    if (/[0-9]/.test(montant[index] || montant[index]=='-' )) {
                      res+=montant[index]  
                    }
                  }else{
                    if (/[0-9]/.test(montant[index])) {
                      res+=montant[index]  
                    }
                  }
                }
                activite.montant_revise=Number(res)

              }
              if (activite.montant.includes(" ")) {
                montant= activite.montant.split(" ")
                res=""
                for (let index = 0; index < montant.length; index++) {
                  res+=montant[index]
                }
                activite.montant=Number(res)
              }else{
                montant = activite.montant.split("")
                res=""
                for (let index = 0; index < montant.length; index++) {
                  if (/[0-9]/.test(montant[index])) {
                    res+=montant[index]  
                  }
                }
                activite.montant=Number(res)

              }
              this.activitesList.push(activite)
            }
          })
          this.send = true
          // console.log(this.activitesList)
          var total_activite=0
          this.activitesList.forEach(activite => {
            total_activite+= activite.montant+activite.montant_revise
          })
          this.activites.forEach(activite => {
            if (activite.observation == "" && activite.montant_revise != 0) {
              this.send = false
              var message = "Veuillez remplir tous les champs observation"
              this.notif.message = message
              this.notif.type = "toast-warning"
              this.notif.activated = "activated"
              setTimeout(
                function() {
                  this.notif.activated = ""
                }.bind(this),
                3000
              )
            }
          })
          if (total_activite<=this.form.montant_definitif) {
            // console.log("montant ok")
            if (this.send) {
              this.formData.activites = this.activitesList
              const pid = this.$route.params.id
              this.updateProgrammes({ data: this.formData, pid: pid })
            }  
          }else{
            var msg = "Le montant total des activités ne doit pas excéder le montant définitif du programme"
            this.notif.message = msg
            this.notif.type = "toast-danger"
            this.notif.activated = "activated"
            setTimeout(
              function() {
                this.notif.activated = ""
              }.bind(this),
              3000
            )  
          }
          
        }else{
          
          var message = "Veuillez remplir tous les champs du formulaire"
          this.notif.message = message
          this.notif.type = "toast-danger"
          this.notif.activated = "activated"
          setTimeout(
            function() {
              this.notif.activated = ""
            }.bind(this),
            3000
          )
        }  
      }else{
        message = "Le total des montants révisés et initiaux des activités doit être inférieure au montant définitif du programme"
        this.notif.message = message
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          3000
        )
      }
      
    },
    goBack() {
      this.$router.push({ name: "DetailProgramme", params: { id: this.$route.params.id } })
    },
    /**
     * voir dans src/assets/js/utils
     */
    getDate() {
      utils.getDate(this, this.gestion_date_debut, this.gestion_date_fin, "programme")
    }
  }
}
</script>
